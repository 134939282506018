import React, { useState, useEffect } from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Message from '../../components/Message/Message';
import Table from '../../components/Table/Table';

import SearchModal from '../../components/Modals/SearchModal';
import search from '../../assets/icons/search-svgrepo-com.svg';

import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../services/Request';
import * as xlsx from 'xlsx';

function MyCards() {
    const isLogin = true;
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [showDownload, setDownload] = useState(false);
    const [excelFile, setExcelFile] = useState();
    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const tipo = "mis tarjetas";

    const [searchData, setSearchData] = useState({
        byStartDate: '',
        byEndDate: ''
    });

    const [paginationCard, setPaginationCard] = useState({
        itemsPerPage: 50,
        totalItems: 0,
        currentPage: 1
    });

    const setCurrentPageCard = (newPage) => {
        setPaginationCard((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }
    const [paginationMov, setPaginationMov] = useState({
        itemsPerPage: 50,
        totalItems: 0,
        currentPage: 1
    });

    const setCurrentPageMov = (newPage) => {
        setPaginationMov((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const tableHeaders = ['Mis Tarjetas', 'Crédito Disponible'];
    const tableStyles = ['accountStyle', 'amountStyle'];
    const tableHeadersData = ['Fecha', 'Hora', 'Tipo de Operación', 'Datos del Lugar', 'Monto']; //'Saldo Post-Movimiento', 
    const tableStylesData = ['centeredStyle', 'centeredStyle', 'textStyle', 'textStyle', 'amountStyle']; //'amountStyle', 

    const [cardList, setCardList] = useState([]);
    const [operations, setOperations] = useState([]);

    const [selectedCard, setSelectedRowCard] = useState(null);
    const [filaSeleccionada, setFilaSeleccionada] = useState(null);

    const handleRowSelectCard = (row, index) => {
        setSelectedRowCard(row);
        renderData(row[2])
        setFilaSeleccionada(index);
    }

    const handleSearch = () => {
        if (showDownload) {
            handleShow();
        }
    }

    const renderData = (id) => {
        setAlert({ tipo: '', msn: '', show: false });
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ADMIN_CARDS + id + "/operations/?token=" + userData.token + "&items=" + paginationMov.itemsPerPage + "&page=" + paginationMov.currentPage, headers)
            .then(({ response, error }) => {
                if (response) {
                    if (response.totalRows === 0) {
                        setDownload(false);
                    } else {
                        setDownload(true);
                    }
                    if (!response.totalRows)
                        setAlert({ tipo: 'warning', msn: 'No se encontraron operaciones registradas', show: true });
                    const movimientos = response.operations.map((item) => {
                        const fechaOp = new Date(item.fechaOperacion);
                        var operationName = '';
                        var operationSign = '-';
                        var style = true;
                        switch (item.tipo) {
                            case 'BALANCE_INQUIRY':
                                operationName = 'Consulta de Saldo';
                                operationSign = '';
                                style = false;
                                break;
                            case 'PURCHASE':
                                operationName = 'Compra';
                                break;
                            case 'WITHDRAWAL':
                                operationName = 'Disposición de Efectivo';
                                break;
                            case 'EXTRACASH':
                                operationName = 'Compra con Retiro';
                                break;
                            case 'REVERSAL_REFUND':
                                operationName = 'Devolución Cancelada';
                                break;
                            case 'REVERSAL_PAYMENT':
                                operationName = 'Pago Devuelto';
                                break;
                            case 'REFUND':
                                operationSign = '';
                                operationName = 'Devolución';
                                style = false;
                                break;
                            case 'PAYMENT':
                                operationSign = '';
                                operationName = 'Pago a su tarjeta';
                                style = false;
                                break;
                            case 'REVERSAL_PURCHASE':
                                operationSign = '';
                                operationName = 'Devolución de Compra';
                                style = false;
                                break;
                            case 'REVERSAL_EXTRACASH':
                                operationSign = '';
                                operationName = 'Devolución de Compra con Efectivo';
                                style = false;
                                break;
                            case 'REVERSAL_WITHDRAWAL':
                                operationSign = '';
                                operationName = 'Devolución de Retiro de Efectivo';
                                style = false;
                                break;
                            default:
                                operationName = 'Desconocida';
                                break;
                        }
                        const op = {
                            fecha: fechaOp.toLocaleDateString('es-mx'),
                            hora: fechaOp.toLocaleTimeString('es-mx'),
                            operacion: operationName,
                            establecimiento: item.establecimiento,
                            monto: operationSign + item.montoCargo,
                            montoOriginal: item.montoCargoOriginal,
                            montoMonedaOriginal: item.monedaCargoOriginal,
                            direccion: item.direccion,
                            id: item.id,
                            estatus: item.estatus,
                            lecturaTarjeta: item.lecturaTarjeta,
                            pais: item.pais,
                            modo: item.trxModo,
                            statusCode: item.statusCode,
                            style: style
                        }
                        return op;
                    });
                    setOperations(movimientos);
                    setPaginationMov((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        setDownload(false);
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setDownload(false);
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setDownload(false);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                    return;
                }
            }, [navigate, paginationMov.currentPage, paginationMov.itemsPerPage, userData.token]);
    };

    useEffect(() => {
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        if (selectedCard) {
            renderData(selectedCard[2]);
        }
    }, [paginationMov.currentPage, paginationMov.itemsPerPage, userData.token]);

    useEffect(() => {
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ADMIN_CARDS + "mycards/?token=" + userData.token + "&items=" + paginationCard.itemsPerPage + "&page=" + paginationCard.currentPage, headers)
            .then(({ response, error }) => {
                if (response) {
                    const itemResult = response.cards.map(
                        (item) => [
                            item.last_four,
                            item.balanceGarantia,
                            item.id,
                            item.nombre,
                            item.telefono,
                            item.estatus
                        ]
                    );
                    setCardList(itemResult);
                    setPaginationCard((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                } else if (error) {
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else {
                        const responseErrors = error.response.data.errors.errors;
                        if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }
                    return;
                }
            });
    }, [navigate, paginationCard.currentPage, paginationCard.itemsPerPage, userData.token]);

    function executeSearch() {
        console.log(searchData['byStartDate']);
        console.log(searchData['byEndDate']);
        // setPaginationCard((prevData) => ({
        //     ...prevData,
        //     currentPage: 1
        // }));
    }

    const handleResultsFile = () => {
        console.log(selectedCard);
        const ws = xlsx.utils.json_to_sheet(operations);
        ws["!cols"] = [{ wch: 15 }, { wch: 15 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 15 }];
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Resultado");
        xlsx.utils.sheet_add_aoa(ws, [["Fecha", "Hora", "Tipo de Operacín", "Datos de Lugar", "Saldo Post-Movimiento", "Saldo"]], { origin: "A1" });
        let buffer = xlsx.write(wb, { bookType: 'xlsx', compression: true, type: 'buffer' });

        let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });

        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "Movimientos SitesPay - " + selectedCard[0];
        link.click();
        URL.revokeObjectURL(link.href);
    };

    /**
     * Quito esta parte visual, hasta revisar que funcione bien... 
     * 
     * lo que seria la linea 294
        {showDownload && (<button className="btn btn-primary" type="button" onClick={handleResultsFile}>Descargar</button>)}

        y la 299
        <button className='btnSearchModal' type="button" onClick={handleSearch}>
            <img className='imgSearch' src={search} alt="" />
        </button>
     */

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <div className='row'>
                                    <div className="col-sm-8 containerTitleAdmin">
                                        <h4 className="subTitle">Mis Tarjetas</h4>
                                    </div>
                                    <div className="col-sm-3 contenBtnAdmin">
                                    </div>
                                    <div className="col-sm-1 content-row">
                                    </div>
                                </div>

                            </div>
                            <div className="bg-container-body">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className='row contentDivRow'>
                                    <div className='col-sm-4 con-table'>
                                        <Table headers={tableHeaders} data={cardList} styles={tableStyles} itemsPerPage={paginationCard.itemsPerPage} from='myCards' onSelect={handleRowSelectCard} filaSeleccionada={filaSeleccionada}
                                            currentPage={paginationCard.currentPage} setCurrentPage={setCurrentPageCard} totalPages={Math.ceil(paginationCard.totalItems / paginationCard.itemsPerPage)} />
                                    </div>
                                    <div className='col-sm-8'>
                                        <Table headers={tableHeadersData} data={operations} styles={tableStylesData} itemsPerPage={paginationMov.itemsPerPage} from='myCards2'
                                            currentPage={paginationMov.currentPage} setCurrentPage={setCurrentPageMov} totalPages={Math.ceil(paginationMov.totalItems / paginationMov.itemsPerPage)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
        </div>
    )
}

export default MyCards

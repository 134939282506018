import React from 'react';

import ghiv from '../../assets/img/GHIV.png';
import xentral from '../../assets/img/XENTRAL.png';
import comillas1 from '../../assets/img/comillas1.png';
import comillas2 from '../../assets/img/comillas2.png';
import star from '../../assets/img/estrellas.png';

function ComponentTheyTrustUs() {
    return (
        <div className="bgProductos3">
            <div className='lineNosotros'>
                <h2 className="titleSolucions">Ellos Confian <br /> en Nosotros</h2>
            </div>
            <div className='row logo-space' >
                <div className='col-sm-6'>
                    <div>
                        <img className='imgNosotros' src={xentral} alt='' />
                    </div>
                    <div>
                        <img className='imgEstrellas' src={star} alt='' />
                    </div>
                    <div>
                        <img className='imgComillas' src={comillas1} alt='' />
                        <div className='nosotrosCol1'>
                            <p className='txtNosotros'>
                                Les comento que nuestra experiencia en la utilización de la tarjeta Site Pay
                                <span className='resaltar'> ha sido muy satisfactoria, considero que es una herramienta muy útil </span>
                                para el negocio de la Central de Abasto y Central Logística XENTRAL en la cual nosotros participamos. <br /> <br />
                                Son muchos los beneficios que le vemos a su producto para nosotros como institución y en de nuestros locatarios, que el
                                <span className='resaltar'> desarrollo de una ejecución exitosa </span> en nuestra Central de Abasto es prioridad de la Dirección General
                            </p>
                        </div>
                        <img className='imgComillas2' src={comillas2} alt='' />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div>
                        <img className='imgNosotros' src={ghiv} alt='' />
                    </div>
                    <div>
                        <img className='imgEstrellas' src={star} alt='' />
                    </div>
                    <div>
                        <div>
                            <img className='imgComillas' src={comillas1} alt='' />
                        </div>
                        <div className='nosotrosCol2'>
                            <p className='txtNosotros2'>
                                El <span className='resaltar'> servicio al cliente </span>
                                de SITESPAY <span className='resaltar'> es excepcional</span>. La
                                <span className='resaltar'> app en muy amigable y ha simplificado la operación</span>.
                                ¡Altamente recomendado!
                            </p>
                        </div>
                        <img className='imgComillas2' src={comillas2} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentTheyTrustUs
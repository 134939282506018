import React, { useState } from 'react'
import Message from '../../components/Message/Message'
import Modal from 'react-bootstrap/Modal';
import '../../css/forms.css'

function ConfirmPasswordOperation({ pass, setPass, doExecute, show, handleClose }) {

    const doExecuteLocal = () => {
        doExecute();
    }
    const clearModal = () => {
        setPass("");
    }
    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); clearModal(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Confirmar Contraseña de Operación</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contModal">
                        <h6 className="sutitle-modal">
                        </h6>
                        <div className="card card-caratula">
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Ingrese su contraseña de operaciones:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="password" id="operationPass" className="form-control txtControl" value={pass} onChange={(e) => setPass(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" onClick={doExecuteLocal} className="btn btn-primary2 btnModal">Confirmar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ConfirmPasswordOperation

import React from 'react';

import menu from "../../assets/icons/menu.svg";
import iconsp from "../../assets/img/LOGO.png";
import "./Header.css";
import MenuIn from "../Menus/MenuIn/MenuIn";
import MenuOut from "../Menus/MenuOut/MenuOut";

function HeaderTwo({ isLogin, isAdmin }) {
    return (
        <div className="">
            <nav className="navbar navbar-expand-md">
                <div className="btn-group">
                    {isAdmin ? (
                        <button
                            className="btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseLateral"
                            aria-expanded="false"
                            aria-controls="collapseWidthExample"
                        >
                            <img className="iconMenuLateral" src={menu} alt=""></img>
                        </button>
                    ) : null}
                    {isLogin ? (
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarScroll"
                            aria-controls="navbarScroll"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <img className="imgmenu" src={menu} alt=""></img>
                        </button>
                    ) : (
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarScrollOut"
                            aria-controls="navbarScrollOut"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <img className="imgmenu" src={menu} alt=""></img>
                        </button>
                    )}
                    <a
                        className="nav-link pos-img"
                        href={isLogin ? "/accounts" : "#login"}
                    >
                        <img className="imgicon" src={iconsp} alt="" />
                    </a>
                </div>

                {isLogin ? <MenuIn /> : <MenuOut />}
            </nav>
        </div>
    )
}

export default HeaderTwo
import React from "react";

import logo from '../../assets/img/ISOTIPO.png';
import cuenta from '../../assets/img/iconoCuenta.png';
import medios from '../../assets/img/iconoMedios.png';
import solition from '../../assets/img/iconoSoluciones.png';

function ComponenteSolutions() {
  return (
    <div className="bgSolutions">
      <div className="lineSolutions">
        <h2 className="titleSolucions">Nuestras Soluciones</h2>
      </div>
      <div className="bgTextSolutions1">
        <p className="textSolutions1">
          Ofrecemos <span className="resaltar"> soluciones financieras integrales </span>
          que cubren la <span className="resaltar"> gestion de pagos,</span><br />
          <span className="resaltar">monitoreo de ventas y protección de datos.</span>
        </p>
        <div className="positionLogo">
          <img className="iconLogoSP" src={logo} alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="bgSolutions1">
            <div className="bgSolutions2">
              <div className="bgSolutions3"></div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="">
            <div className="row col-Solutions">
              <div className="col-2">
                <img className="iconSoluctions" src={cuenta} alt="" />
              </div>
              <div className="col-10">
                <h4 className="subTitleSoluction">Cuenta Empresarial para Tesorería</h4>
                <p className="txtSolutions">
                  Ofrece <span className="resaltar">heramientas avanzadas</span> para el
                  <span className="resaltar"> control de fondos, transferencias rápidas, y conciliación precisa, </span>
                  facilitando una administración eficiente y segura de las
                  <span className="resaltar"> finanzas empresariales.</span>
                </p>
              </div>
            </div>
            <div className="row col-Solutions">
              <div className="col-2">
                <img className="iconSoluctions" src={medios} alt="" />
              </div>
              <div className="col-10">
                <h4 className="subTitleSoluction">Medios de Pago para Nómina</h4>
                <p className="txtSolutions">
                  <span className="resaltar">Soluciones eficientes y seguras para pagar la nomina, </span>
                  incluyendo transferencias electronicas, depósitos directos y trajetas prepagadas.
                </p>
              </div>
            </div>
            <div className="row col-Solutions">
              <div className="col-2">
                <img className="iconSoluctions" src={solition} alt="" />
              </div>
              <div className="col-10">
                <h4 className="subTitleSoluction">Soluciones Financieras con POS/ ATMS</h4>
                <p className="txtSolutions">
                  Ofrecemos <span className="resaltar"> soluciones financieras que integran POS y ATMs </span>
                  para facilitar <span className="resaltar"> transacciones seguras, eficientes y accesibles </span>
                  para tu empresa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponenteSolutions;

import React, { useState } from 'react';

import azure from '../../assets/img/LogoA.png';
import mc from '../../assets/img/MastercardLogo.png';
import pomelo from '../../assets/img/LOGOPOMELO.png';
import logo from '../../assets/img/ISOTIPOtestimonios.png';

function ComponentSuccessStories() {
    return (
        <div className="bgProductos2">
            <div className="lineProducts">
                <h2 className="titleProducts" >Nuestros Partners</h2>
            </div>
            <div className='row space-col-Par'>
                <div className='col-sm-4 col-1'>
                    <img className='logosPar1' src={azure} alt='' />
                </div>
                <div className='col-sm-4 col-3'>
                    <img className='logosPar2' src={mc} alt='' />
                </div>
                <div className='col-sm-4 col-1'>
                    <img className='logosPar1' src={pomelo} alt='' />
                </div>
            </div>
            <div className='bgLogoP'>
                <img className='logoP' src={logo} alt='' />
            </div>
        </div>
    )
}

export default ComponentSuccessStories
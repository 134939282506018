import React, { useEffect, useState, useRef } from "react";
import HeaderTwo from '../../components/Headers/HeaderTwo';
import ComponentLogin from '../../components/login/ComponentLogin';
import ComponentProducts from '../../components/login/ComponentProducts';
import ComponenteSolutions from '../../components/login/ComponenteSolutions';
import ComponentDiscover from '../../components/login/ComponentDiscover';
import ComponentSuccessStories from '../../components/login/ComponentSuccessStories';
import ComponentTheyTrustUs from '../../components/login/ComponentTheyTrustUs';
import ComponentContact from '../../components/login/ComponentContact';
import FooterOut from '../../components/Footer/FooterOut';

function Index() {

    return (
        <div className='bg-index'>
            <div className='menu-index fixed-top'>
                <div className='containerMenu'>
                    <HeaderTwo isLogin={false} />
                </div>
            </div>
            <div className='body-index'>
                <div id="login">
                    <ComponentLogin />
                </div>
                <div id="productos">
                    <ComponentProducts />
                </div>
                <div id="soluciones">
                    <ComponenteSolutions />
                </div>
                <div id="descubre">
                    <ComponentDiscover />
                    <ComponentSuccessStories />
                    <ComponentTheyTrustUs />
                </div>
                <div id="contacto">
                    <ComponentContact />
                </div>
                <div>
                    <FooterOut />
                </div>
            </div>
        </div>
    )
}

export default Index
import React, { useState, useEffect, useRef } from 'react'
import * as xlsx from 'xlsx';
import * as validate from '../../utils/validations';
import { fetchData, postData } from '../../services/Request';
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import '../../components/Table/Table.css';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Table from '../../components/Table/Table';
import Message from '../../components/Message/Message';
import { getInstitutionName2 } from '../../services/institution';
import MultiFilePrintTicket from '../../components/Tickets/MultiFilePrintTicket';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Spinner from '../../components/Spinners/Spinner';

function MultipleTransfers() {
    const isLogin = true;
    const isMenu = false;

    const [fileIsSpeiPayments, setFileIsSpeiPayments] = useState(false);
    const [fileIsTDCWarranties, setFileIsTDCWarranties] = useState(false);
    var whichFileType = 0;
    const [typeFile, setTypeFile] = useState(0);
    // me intriga saber porque hay casos en los que las variables de estado no se encuentran seteadas, cuando fue el paso inmediato anterior
    // var fileIsSpeiPayments = false;
    // var fileIsTDCWarranties = false;
    const [selectedFile, setSelectedFile] = useState(null);
    const [accountOrigin, setAccountOrigin] = useState('');
    const [accountOriginName, setAccountOriginName] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [transactionsDoc, setTransactionsDoc] = useState([]);
    const [transactionsToShow, setTransactionsToShow] = useState([]);
    const [tdcTransactionHeader, setTdcTransactionHeader] = useState({
        totalTrxCount: 0,
        totalTrxAmount: 0
    });
    const [excelFile, setExcelFile] = useState();
    const [accounts, setAccounts] = useState([]);
    const [password, setPassword] = useState('');
    const [isBtnResetDisabled, setIsBtnResetDisabled] = useState(true);
    const [isBtnProcessOpsDisabled, setIsBtnProcessOpsDisabled] = useState(true);
    const [showProgress, setShowProgress] = useState(false);
    const [totalOps, setTotalOps] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const navigate = useNavigate();
    const fileInputRef = useRef();
    const [showOperations, setShowOperations] = useState(false);
    const [showDownloadResultFile, setShowDownloadResultFile] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [alert2, setAlert2] = useState({ tipo: '', msn: '', show: false });

    const headers = ['Cuenta Destino', 'Beneficiario', 'RFC Beneficiario', 'Concepto', 'Monto', 'Referencia Numérica'];
    const resultHeaders = ['Clave Rastreo', 'Cuenta Destino', 'Beneficiario', 'RFC Beneficiario', 'Concepto', 'Monto', 'Referencia Numérica'];
    const tableStyles = ['', '', '', '', '', '', ''];
    const [tableHeaders, setTableHeaders] = useState(headers);
    const [currentDate, setCurrentDate] = useState(new Date());
    
    const [optToken, setOtpToken] = useState('');

    /// para operaciones de archivo de abonos de garantia
    const [isTDCFile, setIsTDCFile] = useState(true);
    const userDataGrl = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setAccounts(response.cuentas)
                } else if (error) {
                    console.log("error:" + error)
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                }
            });
    }, [navigate]);

    const requestOTP = () => {
        setAlert({ tipo: '', msn: '', show: false });
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } };
        fetchData(process.env.REACT_APP_AUTH_REQUEST_OTP + "?token=" + userData.token, headers)
        .then(({ response, error }) => {
            if (response) {
                if(response.result === 'ok') {
                    // setOtpValidated(true);
                }
            } else if (error) {
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                } else if (responseErrors !== undefined) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    setAlert({ tipo: 'danger', msn: 'Error en el systema', show: true });
                }
                return;
            }
        });
    }

    const handleSelectedFile = (event) => {
        const excelFile = event.target.files[0];

        handleReset();

        if (!excelFile) {
            return;
        }

        setExcelFile(excelFile);
        setSelectedFile(excelFile);
        loadSitesPayExcelFile(excelFile)
            .then(res => {
                //console.log('proceso archivo leido', fileIsSpeiPayments, fileIsTDCWarranties, res);
                var count = 0;
                var trxAmount = 0;
                var hasErrors = false;
                var fullErrorList = [];
                var re = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);

                if (whichFileType === 10) {
                    setTypeFile(10);
                    //console.log('proceso archivo de transacciones SPEI', res);
                    res.forEach(trx => {

                        if (!trx.RFC_Beneficiario) {
                            trx.RFC_Beneficiario = 'ND';
                        }

                        if (!re.test(trx.Email)) {
                            trx.Email = '';
                        }

                        trx.id = trx.__rowNum__ + 1;

                        const resultValidation = validateTransactionData(trx);

                        if (resultValidation.length < 1) {
                            count++;
                            trxAmount = trxAmount + parseFloat(trx.Monto);
                        } else {
                            fullErrorList = fullErrorList.concat(resultValidation);
                            hasErrors = true;
                        }
                    });
                } else {
                    if (whichFileType === 11) {
                        setTypeFile(11);
                        //console.log('proceso archivo de transacciones recarga de TDCs', res);
                        res.forEach(trx => {
                            trx.id = trx.__rowNum__ + 1;
                            const resultValidation = validateTDCWarrantyData(trx);
                            if (resultValidation.length < 1) {
                                count++;
                                trxAmount = trxAmount + parseFloat(trx.Monto);
                            } else {
                                fullErrorList = fullErrorList.concat(resultValidation);
                                hasErrors = true;
                            }
                            trx.Cuenta_Destino = trx.Ultimos_4_Digitos_TDC;
                            trx.Destinatario = trx.Telefono;
                            trx.Concepto = 'Abono a TDC XXXX-XXXX-XXXX-' + trx.Ultimos_4_Digitos_TDC;
                            trx.RFC_Beneficiario = 'ND';
                            delete trx.Telefono;
                            delete trx.Ultimos_4_Digitos_TDC;
                        });
                        setTdcTransactionHeader({
                            totalTrxCount: count,
                            totalTrxAmount: trxAmount
                        });
                    } else {
                        fullErrorList = 'Archivo con formato erroneo o no es original.';
                        hasErrors = true;
                    }
                }

                if (count < 1) {
                    setAlert({ tipo: 'danger', msn: 'Archivo dañado o no se cuenta con operaciones para procesar', show: true });
                } else if (!hasErrors) {
                    setIsBtnProcessOpsDisabled(false);
                    setTotalOps(count);
                    setTotalAmount(dollarUS.format(trxAmount));
                }

                if (hasErrors) {
                    let displayText = "";
                    fullErrorList.forEach(errorText => {
                        displayText = displayText + errorText + '\n';
                    });
                    setAlert({ tipo: 'danger', msn: 'Archivo contiene operaciones con errores favor de revisar:\n' + displayText, show: true });
                    setIsBtnResetDisabled(false);
                } else {
                    const resTrasactions = res.map(item => ({
                        Cuenta_Destino: item.Cuenta_Destino,
                        Destinatario: item.Destinatario,
                        RFC_Beneficiario: item.RFC_Beneficiario,
                        Concepto: item.Concepto,
                        Monto: item.Monto,
                        Referencia_Numerica: item.Referencia_Numerica,
                        Email: item.Email,
                        Codigo_Banco: item.Codigo_Banco,
                        id: item.id
                    }));

                    setTransactions(resTrasactions);
                    setTransactionsToShow(resTrasactions);
                    setIsBtnResetDisabled(false);
                    setShowOperations(true);
                }

            })
    };

    const loadSitesPayExcelFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            let fileVersion = {};
            let result = {};
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = xlsx.read(data, { type: "binary" })
                fileVersion = xlsx.utils.sheet_to_json(workbook.Sheets['SitesPay1'], { header: 'A', range: 0, sheetRows: 1 });
                if (fileVersion[0] && (fileVersion[0].A === "tdcV1.1")) {
                    setFileIsTDCWarranties(true);
                    whichFileType = 11;
                    setTypeFile(11);
                    //fileIsTDCWarranties = true;
                } else {
                    if ((fileVersion.length > 1) && (fileVersion[0].I === "v1.0")) {
                        setTypeFile(10);
                        whichFileType = 10;
                        setFileIsSpeiPayments(true);
                        //fileIsSpeiPayments = true;
                    } else {
                        setAlert({ tipo: 'danger', msn: 'El archivo seleccionado no tiene el formato esperado.', show: true });
                        return;
                    }
                }

                result['SitesPay1'] = xlsx.utils.sheet_to_json(workbook.Sheets['SitesPay1'], { range: 10 });
                //console.log('results: ', result);
                resolve(result.SitesPay1);
            }
            reader.readAsArrayBuffer(file)
            //reader.readAsBinaryString(file)
        })
    }

    function validateTransactionData(trx) {
        const validationResult = [];

        const addValidation = (errorMessage) => {
            if (errorMessage) {
                validationResult.push(`${errorMessage} en registro ${trx.id}`);
            }
        };
        addValidation(validate.length(trx.Cuenta_Destino, 16, 18, "Cuenta destino erronea"));
        if (trx.Cuenta_Destino.length === 16) {
            if (!trx.Codigo_Banco)
                addValidation('Cuenta destino es tarjeta, favor de seleccionar el Banco de la lista en su excel');
        }
        addValidation(validate.numeric(trx.Cuenta_Destino, "Cuenta destino erronea"));
        addValidation(validate.numeric(trx.Referencia_Numerica, "Número de referencia inválido"));
        addValidation(validate.maxNumber(trx.Referencia_Numerica, 9999999, "Número de referencia inválido"));
        addValidation(validate.numeric(trx.Monto, "Monto inválido"));
        addValidation(validate.maxLength(trx.Destinatario, 40, "Beneficiario demasiado largo"));
        addValidation(validate.maxLength(trx.RFC_Beneficiario, 18, "RFC demasiado largo"));
        addValidation(validate.maxLength(trx.Concepto, 40, "Concepto demasiado largo"));

        return validationResult;
    }

    function validateTDCWarrantyData(trx) {
        const validationResult = [];

        const addValidation = (errorMessage) => {
            if (errorMessage) {
                validationResult.push(`${errorMessage} en registro ${trx.id}`);
            }
        };
        addValidation(validate.length(trx.Ultimos_4_Digitos_TDC, 4, 4, "Últimos 4 dígitos erroneos"));
        addValidation(validate.numeric(trx.Ultimos_4_Digitos_TDC, "Últimos 4 dígitos inválidos"));
        addValidation(validate.numeric(trx.Referencia_Numerica, "Número de referencia inválido"));
        addValidation(validate.maxNumber(trx.Referencia_Numerica, 9999999, "Número de referencia inválido"));
        addValidation(validate.numeric(trx.Monto, "Monto inválido"));
        addValidation(validate.length(trx.Telefono, 10, 10, "Número de teléfono inválido (debe ser de 10 dígitos)"));
        addValidation(validate.numeric(trx.Telefono, "Número de teléfono inválido (debe ser numérico de 10 dígitos)"));
        if (trx.Email) addValidation(validate.email(trx.Email, "Email inválido"));

        return validationResult;
    }

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const handleReset = () => {
        setAccountOrigin('');
        setAccountOriginName('');
        setTransactions([]);
        setTransactionsDoc([]);
        setPassword('');
        setOtpToken('');
        fileInputRef.current.value = '';
        setSelectedFile('');
        setIsBtnProcessOpsDisabled(true);
        setIsBtnResetDisabled(true);
        setTotalOps();
        setTotalAmount();
        closeAlert();
        closeAlert2();
        setShowDownloadResultFile(false);
        setTableHeaders(headers);
        setShowOperations(false);
        setProgress(0);
        setShowProgress(false);
        setShowSpinner(false);
    }

    const handleOnChange = (e) => {
        setAccountOrigin(e.target.value);
        const filtered = accounts.filter((account) => account.accountNumber.includes(e.target.value));
        setAccountOriginName(filtered[0].beneficiary);
    }

    const handleTransactions = () => {
        setShowSpinner(true);
        setIsBtnProcessOpsDisabled(true);
        closeAlert();

        if( userDataGrl.opsOTPRequired ) {
            // necesitamos enviar 2FA token, por lo tanto debe existir
            if( optToken.length < 6 ) {
                setAlert({ tipo: 'danger', msn: 'Requiere token de autenticación', show: true });
                setShowSpinner(false);
                setIsBtnProcessOpsDisabled(false);
                return;    
            }
        }


        if (!accountOrigin) {
            setIsBtnProcessOpsDisabled(false);
            setAlert({ tipo: 'danger', msn: 'Favor de seleccionar cuenta origen', show: true });
            setShowSpinner(false);
            return;
        }

        if (!password) {
            setIsBtnProcessOpsDisabled(false);
            setShowSpinner(false);
            setAlert({ tipo: 'danger', msn: 'Debe ingresar su contraseña de operaciones', show: true });
            return;
        }

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
        const filename = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, excelFile.name);

        postData(process.env.REACT_APP_ACCOUNT_FILEOPS + "?token=" + userData.token, { filename }, headers)
            .then(({ response, error }) => {
                if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                    console.log("E fileops: " + error);
                    return;
                }
                // console.log(fileIsSpeiPayments);
                // console.log(response);

                // if (response && response[0].Total && !window.confirm("Parece que este archivo ya fue procesado: ¿seguro lo quieres volver a procesar?")) {
                //     setIsBtnProcessOpsDisabled(false);
                //     setShowSpinner(false);
                //     return;
                // }
                if (response && response.length) {
                    if(response[0].Total && !window.confirm("Parece que este archivo ya fue procesado: ¿seguro lo quieres volver a procesar?")) {
                        setIsBtnProcessOpsDisabled(false);
                        setShowSpinner(false);
                        return;
                    }
                }

                const encryptedPass = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, password);
                const data = {
                    opsPass: password
                }
                const encryptedData = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(data));

                postData(process.env.REACT_APP_AUTH_VALIDATEOPS + "?token=" + userData.token, { data: encryptedData }, headers)
                    .then(({ response, error }) => {
                        if (error) {
                            if (error.response.status === 401) {
                                localStorage.removeItem('userData');
                                navigate('/');
                                return;
                            }
                            setAlert({ tipo: 'danger', msn: 'Contraseña de operación incorrecta', show: true });
                            setIsBtnProcessOpsDisabled(false);
                            setShowSpinner(false);
                            //console.log("E VALIDATEOPS: " + error);
                            return;
                        }
                        if (fileIsSpeiPayments) {
                            //console.log(transactions);
                            //if (response === 'valid password') {
                            const transactionsToProcess = transactions.map(item => ({
                                'trxType': 7,
                                'accountFrom': accountOrigin,
                                'accountTo': item.Cuenta_Destino,
                                'beneficiary': item.Destinatario,
                                'beneficiaryRfc': item.RFC_Beneficiario,
                                'transferAmount': item.Monto.toString(),
                                'concept': item.Concepto,
                                'refNumber': item.Referencia_Numerica.toString(),
                                'opsPass': encryptedPass,
                                'opsOtpToken': optToken,
                                'email': item.Email,
                                'toBankCode': item.Codigo_Banco ? item.Codigo_Banco.toString() : ''
                            }));
                            //console.log(transactionsToProcess);
                            const transactionsString = JSON.stringify(transactionsToProcess);
                            const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, transactionsString);

                            postData(process.env.REACT_APP_ACCOUNT_MULTIPAYMENTS + "?token=" + userData.token, { payment }, headers, (240 * 1000))
                                .then(({ response, error }) => {
                                    //console.log(response);
                                    if (error) {
                                        if (error.response.status === 401) {
                                            localStorage.removeItem('userData');
                                            navigate('/');
                                        }
                                        setAlert({ tipo: 'danger', msn: 'Error al procesar su solicitud. Inténtelo mas tarde', show: true });
                                        console.log("E MULTIPAYMENTS: " + error);
                                        setShowSpinner(false);
                                        return;
                                    }
                                    if (response) {
                                        let transactionsErrors = 0;
                                        const transactionsResponse = JSON.parse(response);

                                        const transactionsResult = transactionsResponse.map((transaction) => [
                                            transaction.claveRastreo,
                                            transaction.accountFrom,
                                            parseInt(transaction.fromBank) ? getInstitutionName2(parseInt(transaction.fromBank)) : '*****',
                                            transaction.fromAccountName,
                                            transaction.accountTo,
                                            parseInt(transaction.toBank) ? getInstitutionName2(parseInt(transaction.toBank)) : '*****',
                                            transaction.beneficiary,
                                            transaction.beneficiaryRfc,
                                            transaction.concept,
                                            transaction.transferAmount,
                                            transaction.refNumber,
                                            transaction.trxDate
                                        ]);

                                        setTableHeaders(resultHeaders);
                                        setTransactions(transactionsResult);
                                        const transactionsResult2 = transactionsResponse.map((transaction) => [
                                            transaction.claveRastreo,
                                            transaction.accountTo,
                                            transaction.beneficiary,
                                            transaction.beneficiaryRfc,
                                            transaction.concept,
                                            transaction.transferAmount,
                                            transaction.refNumber
                                        ]);
                                        setTransactionsToShow(transactionsResult2);

                                        transactionsResponse.forEach(item => {
                                            if (item.errorNumber) {
                                                transactionsErrors++;
                                            }
                                        });

                                        if (transactionsErrors > 0) {
                                            setAlert2({ tipo: 'warning', msn: 'Operaciones procesadas parcialmente, revise el archivo con el resultado.', show: true });
                                        } else {
                                            setAlert2({ tipo: 'success', msn: 'Operaciones procesadas.', show: true });
                                        }

                                        setShowSpinner(false);
                                        setShowDownloadResultFile(true);
                                        setIsBtnProcessOpsDisabled(true);

                                        const summary = {
                                            filename: excelFile.name,
                                            totalOps: parseInt(totalOps)
                                        }

                                        const cryptoOps = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(summary));

                                        postData(process.env.REACT_APP_ACCOUNT_SAVEFILEOPS + "?token=" + userData.token, { cryptoOps }, headers)
                                            .then(({ response, error }) => {
                                                if (error) {
                                                    if (error.response.status === 401) {
                                                        localStorage.removeItem('userData');
                                                        navigate('/');
                                                    }
                                                    console.log("E SAVEFILEOPS: " + error);
                                                    return;
                                                }
                                            });
                                    } // else "unknown error" ???
                                });
                            //}
                        }

                        if (fileIsTDCWarranties) {
                            // armo el obj a enviar al endpoint
                            const tdcDeposits = {
                                totalTdcTransactions: tdcTransactionHeader.totalTrxCount,
                                totalTdcAmount: tdcTransactionHeader.totalTrxAmount,
                                accountFrom: accountOrigin,
                                opsPass: encryptedPass,
                                opsOtpToken: optToken,
                                transactions: transactions
                            };
                            //console.log(tdcDeposits);
                            const transactionsString = JSON.stringify(tdcDeposits);
                            const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, transactionsString);
                            postData(process.env.REACT_APP_ACCOUNT_MULTICARDPAYMENTS + "?token=" + userData.token, { payment }, headers, (180 * 1000))
                                .then(({ response, error }) => {
                                    //console.log(response);
                                    if (error) {
                                        if (error.response.status === 401) {
                                            localStorage.removeItem('userData');
                                            navigate('/');
                                        } else {
                                            if (error.response.status === 406) {
                                                var errorText = "\n";
                                                error.response.data.errors.errors.map((error) => {
                                                    errorText += error.message;
                                                    error.field ? errorText += ' Error en campo: ' + error.field + '\n' : errorText = errorText + '\n';
                                                });
                                                setAlert({ tipo: 'danger', msn: error.response.data.errors.errorMessage + errorText, show: true });
                                                //console.log("E MULTIPAYMENTS: " + error);
                                                setShowSpinner(false);
                                                return;
                                            }
                                        }
                                        setAlert({ tipo: 'danger', msn: 'Error al procesar su solicitud. Inténtelo mas tarde', show: true });
                                        setShowSpinner(false);
                                        return;
                                    }

                                    if (response) {
                                        /*
                                        |Multi TDC deposit result:[
                                            {"Cuenta_Destino":"9483","Destinatario":"4423590986","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-9483","Monto":1,"Referencia_Numerica":"1234567","Email":"mail.corro@server.com","id":12,"internalId":67,"error":"","claveRastreo":"202405162021096081148"},
                                            {"Cuenta_Destino":"0153","Destinatario":"4423670467","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-0153","Monto":5,"Referencia_Numerica":"123123","Email":"mail@mailserver.com","id":13,"internalId":68,"error":"","claveRastreo":"202405162021096081148"},
                                            {"Cuenta_Destino":"8833","Destinatario":"4423590986","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-8833","Monto":3,"Referencia_Numerica":"123123","id":14,"internalId":69,"error":"","claveRastreo":"202405162021096081148"},
                                            {"Cuenta_Destino":"9483","Destinatario":"4423590986","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-9483","Monto":1,"Referencia_Numerica":"123458","id":15,"internalId":70,"error":"","claveRastreo":"202405162021096081148"},
                                            {"Cuenta_Destino":"0153","Destinatario":"4423670467","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-0153","Monto":5,"Referencia_Numerica":"234","id":16,"internalId":71,"error":"","claveRastreo":"202405162021096081148"},
                                            {"Cuenta_Destino":"8833","Destinatario":"4423590986","RFC_Beneficiario":"ND","Concepto":"Abono a TDC XXXX-XXXX-XXXX-8833","Monto":3,"Referencia_Numerica":"123123","id":17,"internalId":72,"error":"","claveRastreo":"202405162021096081148"}
                                        ]
                                        */

                                        // let transactionsErrors = 0;
                                        let transactionsResponse = JSON.parse(response);
                                        //console.log("Multi TDC deposit result: " + response);
                                        //Multi TDC deposit result: [object Object],[object Object],[object Object],[object Object],[object Object],[object Object]

                                        /**
                                         * 
                                         * TODO: @Iris, este SWITCH no está de más ???
                                         * segun yo aqui siempre será 11
                                         */
                                        switch (typeFile) {
                                            case 10:
                                                const transactionsResult1 = transactionsResponse.map((transaction) => [
                                                    transaction.claveRastreo,
                                                    transaction.accountTo,
                                                    transaction.beneficiary,
                                                    transaction.beneficiaryRfc,
                                                    transaction.concept,
                                                    transaction.transferAmount,
                                                    transaction.refNumber
                                                ]);
                                                setTransactions(transactionsResult1);
                                                break;
                                            case 11:
                                                if( transactionsResponse.trxProcessed === transactionsResponse.totalTdcTransactions ) {
                                                    setAlert2({ tipo: 'success', msn: 'Operaciones procesadas.', show: true });
                                                } else {
                                                    setAlert2({ tipo: 'warning', msn: 'Hubo un error con su procesamiento, favor de revisar: ' + transactionsResponse.claveRastreo, show: true });
                                                }
                                                const transactionsResult = transactionsResponse.transactions.map((transaction, i) => [
                                                    transaction.claveRastreo + '-' + (i + 1),
                                                    accountOrigin,
                                                    parseInt('90659') ? getInstitutionName2(parseInt('90659')) : '*****',
                                                    accountOriginName,
                                                    transaction.Cuenta_Destino,
                                                    transaction.Destinatario,
                                                    transaction.Email,
                                                    transaction.Concepto,
                                                    transaction.Monto,
                                                    transaction.Referencia_Numerica,
                                                    currentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' ' + currentDate.toLocaleTimeString(),
                                                ]);
                                                const doc = transactionsResponse.transactions.map((transaction, i) => [
                                                    transaction.claveRastreo,
                                                    accountOrigin,
                                                    parseInt('90659') ? getInstitutionName2(parseInt('90659')) : '*****',
                                                    accountOriginName,
                                                    transaction.Cuenta_Destino,
                                                    transaction.Destinatario,
                                                    transaction.Email,
                                                    transaction.Concepto,
                                                    transaction.Monto,
                                                    transaction.Referencia_Numerica,
                                                    currentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' ' + currentDate.toLocaleTimeString(),
                                                ]);
                                                setTransactions(transactionsResult);
                                                setTransactionsDoc(doc);
                                                break;
                                            default: console.log('null'); break;
                                        }


                                        // transactionsResponse.forEach(item => {
                                        //     if (item.errorNumber) {
                                        //         transactionsErrors++;
                                        //     }
                                        // });

                                        // if (transactionsErrors > 0) {
                                        //     setAlert2({tipo: 'warning', msn: 'Operaciones procesadas parcialmente, revise el archivo con el resultado.', show: true});
                                        // } else {
                                        //     setAlert2({tipo: 'success', msn: 'Operaciones procesadas.', show: true});
                                        // }

                                        setShowSpinner(false);
                                        setShowDownloadResultFile(true);
                                        setIsBtnProcessOpsDisabled(true);

                                        const summary = {
                                            filename: excelFile.name,
                                            totalOps: parseInt(totalOps)
                                        }
                                        const cryptoOps = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(summary));
                                        postData(process.env.REACT_APP_ACCOUNT_SAVEFILEOPS + "?token=" + userData.token, { cryptoOps }, headers)
                                            .then(({ response, error }) => {
                                                if (error) {
                                                    if (error.response.status === 401) {
                                                        localStorage.removeItem('userData');
                                                        navigate('/');
                                                    }
                                                    return;
                                                }
                                            });
                                    }
                                });
                        }

                    });
            });
    };

    const handleResultsFile = () => {
        const ws = xlsx.utils.json_to_sheet(transactions);
        ws["!cols"] = handelColumFile(typeFile);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Resultado");
        xlsx.utils.sheet_add_aoa(ws, handleHeaderFile(typeFile), { origin: "A1" });
        let buffer = xlsx.write(wb, { bookType: 'xlsx', compression: true, type: 'buffer' });

        let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });

        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "Resultado SitesPay - " + excelFile.name;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    const handelColumFile = (type) => {
        switch (type) {
            case 10:
                return [{ wch: 30 }, { wch: 20 }, { wch: 30 }, { wch: 40 }, { wch: 20 }, { wch: 30 }, { wch: 40 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 18 }, { wch: 25 }];
            case 11:
                return [{ wch: 30 }, { wch: 20 }, { wch: 30 }, { wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 40 }, { wch: 15 }, { wch: 18 }, { wch: 25 }];
            default:
                break;
        }
    }

    const handleHeaderFile = (type) => {
        switch (type) {
            case 10:
                return [["Clave de Referencia", "Cuenta Origen", "Institución origen", "Nombre Origen", "Cuenta Destino", "Insitución Destino", "Destinatario", "RFC Destinatario", "Concepto", "Monto", "Referencia Numérica", "Fecha y hora"]];
            case 11:
                return [["Clave de Referencia", "Cuenta Origen", "Institución origen", "Nombre Origen", "Tarjeta Destino", "Teléfono del Destinatario", "Correo del Destinatario", "Concepto", "Monto", "Referencia Numérica", "Fecha y hora"]];
            default:
                break;
        }
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const closeAlert2 = () => {
        setAlert2({ tipo: '', msn: '', show: false });
    };

    const [progress, setProgress] = useState(0);

    const handleResultsPrint = async () => {
        setShowProgress(true);
        const zip = new JSZip();
        // Generar PDFs en lotes para evitar el consumo excesivo de memoria
        const batchSize = 10;
        let data = [];

        if (typeFile === 11) {
            data = transactionsDoc;
        } else {
            data = transactions
        }
        const totalTransactions = data.length;
        let processedTransactions = 0;

        for (let i = 0; i < data.length; i += batchSize) {
            const batch = data.slice(i, i + batchSize);
            const pdfPromises = batch.map((transaction, index) => generatePDF(transaction, i + index));

            try {
                const pdfBlobs = await Promise.all(pdfPromises);
                pdfBlobs.forEach((pdfBlob, index) => {
                    zip.file(`Comprobante ${excelFile.name.substring(0, excelFile.name.indexOf(".xls"))} - Transaccion_${i + index + 1}.pdf`, pdfBlob);
                    processedTransactions++;
                    const newProgress = (processedTransactions / totalTransactions) * 100;
                    setProgress(newProgress);
                });
            } catch (error) {
                console.error('Error al generar PDFs:', error);
            }
        }

        try {
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            setShowProgress(false);
            setProgress(0);
            saveAs(zipBlob, `Comprobantes ${excelFile.name.substring(0, excelFile.name.indexOf(".xls"))}.zip`);
        } catch (error) {
            console.error('Error al generar el archivo ZIP:', error);
        }
    };

    const generatePDF = async (transaction, index) => {
        const element = document.createElement('div');
        const ticketPdfHtml = ReactDOMServer.renderToString(<MultiFilePrintTicket data={[transaction]} type={typeFile} />);
        element.innerHTML = ticketPdfHtml;

        const options = {
            margin: 1,
            filename: `Comprobante ${excelFile.name.substring(0, excelFile.name.indexOf(".xls"))} - Transaccion_${index + 1}.pdf`,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        return html2pdf().from(element).set(options).outputPdf('blob');
    };

    // const handleResultsPrintZip = async () => {
    //     const printWindow = window.open("", "", "height=800,width=800");
    //     const ticketHtml = ReactDOMServer.renderToString(
    //         <MultiFilePrintTicket data={transactions} />
    //     );
    //     printWindow.document.write(ticketHtml);
    //     printWindow.document.close();
    // };

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-acconts">
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-body-container-bg'>
                                <div className="row contentTitle">
                                    <div className="col-sm-6">
                                        <h4 className="subTitle2">Múltiples operaciones (archivo)</h4>
                                    </div>
                                    <div className="col-sm-6 justify-content-end container-link">
                                        <p className='text-link'>
                                            <a href="/files/SitesPayTemplate.xlsx" download className="link-primary">
                                                Descargar archivo base para realizar múltiples operaciones SPEI
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-body-card">
                                <hr />
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className='contentData'>
                                    <p className="formLabel2">Seleccionar el archivo a procesar</p>
                                    <div className="row">
                                        <div className="col-md-9 container-file">
                                            <div className="row file-upload-container">
                                                <div className="col-sm-4 col-btn-choose">
                                                    <label className="file-upload-button" htmlFor="fileInput">
                                                        <span className='choose-file'>Choose file</span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        accept=".xlsx"
                                                        style={{ display: 'none' }}
                                                        onChange={handleSelectedFile}
                                                        ref={fileInputRef}
                                                    />
                                                    <input type="text" className="form-control txtControl" readOnly
                                                        value={selectedFile ? selectedFile.name : "No file chosen"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <button disabled={isBtnResetDisabled} onClick={handleReset} className="btn-primary" type="button">Limpiar datos</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isTDCFile &&
                                    <div>

                                    </div>
                                }
                                {showOperations && (
                                    <>
                                        <hr />
                                        <div className="row contentData">
                                            <div className="col-sm-5">
                                                <div className="mb-3">
                                                    <label className="labelTitle">Seleccione la cuenta origen de la cual se dispersara</label>
                                                    <select className="form-select select-option" id="selectOption2" value={accountOrigin} onChange={handleOnChange}>
                                                        <option value=''>Seleccione la cuenta origen</option>
                                                        {accounts.map((account) => (
                                                            <option
                                                                key={account.id}
                                                                value={account.accountNumber}>
                                                                {account.accountNumber} - {account.description}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 dataRow">
                                                <div className="mb-3">
                                                    <label className="labelTitle">Ingrese su contraseña de operaciones</label>
                                                    <input type="password" value={password} className="form-control" placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                { (userDataGrl.opsOTPRequired===1) &&
                                                <div className="datas">
                                                    <label className="labelTitle">Ingrese token:</label>
                                                    <input type="text" id="optToken" className="form-control txtControl" value={optToken} onChange={(e) => setOtpToken(e.target.value)} />
                                                    <button type="button" onClick={requestOTP} className="btn btn-primary mt-3" >Solicitar token</button>
                                                </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="row contentData">
                                            <div className="col-sm-5">
                                                <label className="labelTitle text-end">Total de operaciones a procesar:</label>
                                                <label className="labelTitle text-end">{totalOps}</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="labelTitle text-end">Monto total de las operaciones: </label>
                                                <label className="labelTitle text-end">{totalAmount}</label>
                                            </div>
                                            <div className="col-sm-3"></div>
                                        </div>
                                        {showProgress && (
                                            <ProgressBar progress={parseInt(progress)} texto={"Generando reportes"} />
                                        )}
                                        <div className="row contentData">
                                            <div className="col-sm-3 div2">
                                                <p className="subTitle3">Transacciones a procesar</p>
                                            </div>
                                            <div className="col-sm-3 div2">
                                                {showDownloadResultFile && (
                                                    <button onClick={handleResultsPrint} className="btn btn-primary" type="button" >Descargar Comprobantes</button>
                                                )}
                                            </div>
                                            <div className="col-sm-3 div2">
                                                {showDownloadResultFile && (
                                                    <button onClick={handleResultsFile} className="btn btn-download" type="button">Descargar Excel</button>
                                                )}
                                                {showSpinner && (
                                                    <Spinner texto={"Procesando..."} />
                                                )}
                                            </div>
                                            <div className="col-sm-3 div2">
                                                <button disabled={isBtnProcessOpsDisabled} onClick={handleTransactions} className="btn btn-primary" type="button">Realizar operaciones</button>
                                            </div>
                                            <Message alert={alert2} closeAlert={closeAlert2} />
                                        </div>
                                        <hr />
                                        <div>
                                            <Table headers={tableHeaders} data={transactionsToShow} styles={tableStyles} itemsPerPage={50} menuIcon='' isMenu={isMenu} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MultipleTransfers
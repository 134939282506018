import React from 'react';

import phone from '../../assets/img/iconocel.png';
import mail from '../../assets/img/iconomail2.png';
import whats from '../../assets/icons/whatsapp.svg'
import redes from '../../assets/img/redes.png';
import mf from '../../assets/img/iconomailfooter.png';
import pf from '../../assets/img/iconocelFOOTER.png';
import wf from '../../assets/icons/whatsapp2.svg'

/**
 * 
 *                             <p className='txtFoterContact'>Ingrese su correo electrónico aquí</p>
                            <input type="text" className="inputFormContact" placeholder="Escribe tu correo aquí" />

                            <img className='redes' src={redes} alt='' />

 */

function ComponentContact() {
    const phoneNumber = '3331731186'; // Reemplaza con el número de teléfono
    const message = '';

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <div className="bgContact">
            <div className='colContacto2'>
                <div className='row foterContact'>
                    <div className='col-sm-4'>
                        <h5 className='titleFoterContact'>Finanzas</h5>
                        <p className='txtFoterContact'>soluciones financieras accesibles y seguras para todos</p>
                        <div className='bgIconFoter'>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <h5 className='titleFoterContact'>Contacto</h5>
                        <div>
                            <p>
                                <img src={mf} alt="" className='iconHelp' />
                                <a className='txtHelpF'>info@sitespay.mx</a>
                            </p>
                            <p>
                                <img src={pf} alt="" className='iconHelp' />
                                <a className='txtHelpF'>+52 442 245 0020</a>
                            </p>
                            <p>
                                <img src={wf} alt="" className='iconHelp' />
                                <a className='txtHelpF' href={whatsappURL} target="_blank" rel="noopener noreferrer">+52 333 173 1186</a>
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <h5 className='titleFoterContact'>Contacto</h5>
                        <div>
                            <p className='txtFoterContact'>¿Necesitas más información? Mándanos un correo y un ejecutivo de cuenta te contactará</p>
                            <div className='spaceContactF'>
                                <a href="mailto:info@sitespay.mx" ><button className="btn btnContact" type="button">Solicitar más información</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentContact
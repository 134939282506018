import React from 'react'

function AccountCoverTicket({data, id, img}) {
    return (
        <html>
            <head>
                <title>Carátula de cuenta SitesPay</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous" />
                <link href="./Tickets.css" rel="stylesheet" />
                <style>
                    {`
                        body {
                            font-family: 'Open Sans', sans-serif;
                        }

                        .imgicon{
                            width: 220px;
                        }

                        .card{
                            background-color: #E6E6E6;
                        }

                        .card-ticket {
                            background-color: #fff;
                        }

                        .title-ticket{
                            color: #4184EC;
                        }

                        .card-header {
                            border-radius: 16px;
                            background: #f8f9fa;
                            border-bottom: none;
                            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
                        }

                        .ticket-header{
                            height: 50px;
                            position: relative;
                            z-index: 1;
                            margin-left: -40px; /* Ajustado a margin-left en lugar de padding-left */
                            margin-right: -40px;
                            border-radius: 8px;
                            background: #f8f9fa;
                            box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15); /* Larger shadow */
                            padding-top: 10px;
                            margin-bottom: 20px;
                        }

                        .text-header-title{
                            margin-left: 40px;
                        }

                        .card-body{
                            margin-left: 60px;
                            margin-right: 60px;
                            margin-top: -2px;
                            border-bottom-right-radius: 16px;
                            border-bottom-left-radius: 16px;
                            box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
                            margin-bottom: 40px;
                        }

                        .card-body h5{
                            color: #4184EC;
                        }

                        .ticket-content p {
                            font-size: 16px;
                            color: #000;
                        }

                        .card-footer h6 {
                            font-size: 22px;
                            color: #4184EC;
                        }

                        .card-footer{
                            height: 70px;
                            display: block;
                            border-top: none;
                            background: #f8f9fa;
                            align-items: center;
                            text-align: center;
                            padding-top: 20px;
                            box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.25);
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="card card-ticket">
                    <div className="card-header">
                        <div className="clearfix">
                            <div className='float-start'>
                                <img className="imgicon" src={img} alt="" />
                            </div>
                            <div className='float-end'>
                                <h1 className="p-2 title-ticket">Carátula de cuenta</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="ticket" id='ticket'>
                        <div className='row'>
                                <div className='col-6 text-center'>
                                    <h5>Institución Bancaria:</h5>
                                </div>
                                <div className='col-6 text-center'>
                                    <h5>ASP Integra Opciones</h5>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='row'>
                                <div className='col-6 text-center'>Cuenta Clabe:</div>
                                <div className='col-6 text-center'> {data.clabe} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Registro Federal de Contribuyente:</div>
                                <div className='col-6 text-center'> {data.rfc} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Razón Social:</div>
                                <div className='col-6 text-center'> {data.razonSocial} </div>
                            </div>
                            <hr></hr>
                            <div className='text-center'><h5>Dirección</h5></div>
                            <hr></hr>
                            <div className='row'>
                                <div className='col-6 text-center'>Calle:</div>
                                <div className='col-6 text-center'> {data.callePrincipal} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Número Exterior:</div>
                                <div className='col-6 text-center'> {data.numExterior} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Interior:</div>
                                <div className='col-6 text-center'> {data.numInterior} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Colonia:</div>
                                <div className='col-6 text-center'> {data.colonia} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Ciudad:</div>
                                <div className='col-6 text-center'> {data.ciudad} </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-center'>Código Postal:</div>
                                <div className='col-6 text-center'> {data.codPostal} </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <h6>SitesCapital S.A. DE C.V., SOFOM, E.N.R</h6>
                    </div>
                </div>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>
            </body>
        </html>
    )
}

export default AccountCoverTicket

import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'

import Company from '../../components/AdminCompany/Company';
import Representative from '../../components/AdminCompany/Representative';
import ProfileCompany from '../../components/AdminCompany/ProfileCompany';
import CreditLine from '../../components/AdminCompany/CreditLine';
import { useParams } from 'react-router-dom';

import { fetchData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';

function UpdateCompany() {
    const { id } = useParams();
    const navigate = useNavigate();

    const isLogin = true;
    const selectedOption = "2";
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [updateData, setUpdateData] = useState(1);

    const [companyData, setCompanyData] = useState({
        perfilId: 0,
        domicilioId: 0,
        repLegalId: 0,
        razon_social: '',
        rfc: '',
        IdIdentificacion: '0',
        numIdentificacion: '',
        fechaCreacion: '',
        entidad: 0,
        pais: "117",
        nacionalidad: 0,
        serieFirmaElect: '',
        giro: 0,
        telefono: '',
        geolocalizacion: '',
        email: '',
        denominacion: 0,
        udnId: 0,
        nivelCuenta: 0,
        uuid: '',

        idCliente: 0,
        nombre: '',
        costoFijoSpeiIn: 0,
        costoPctSpeiIn: 0,
        costoFijoSpeiOut: 0,
        costoPctSpeiOut: 0,
        tieneDist: 0,
        perfil: {
            ingresosMensuales: 0,
            montoMax: 0,
            cantOpMensual: 0
        },
        domicilio: {
            callePrincipal: '',
            calleSecundaria2: '',
            calleSecundaria3: '',
            ciudad: '',
            codigoPostal: '',
            colonia: '',
            id: 0,
            numExterior: '',
            numInterior: ''
        },
        repLegal: {
            persona: {
                repLegalId: 0,
                domicilioId: 0,
                id: 0,
                apellidoPaterno: '',
                apellidoMaterno: '',
                nombre: '',
                celular: '',
                correo: '',
                curp: '',
                entidadNacimiento: 0,
                fechaNacimiento: '',
                idNacionalidad: 0,
                idOcupacion: 0,
                numIdentificacionOf: '',
                rfc: '',
                serieFirmaElect: '',
                telefono: '',
                sexo: '',
                tipoIdentificacionOf: 0
            },
            domicilio: {
                callePrincipal: '',
                calleSecundaria2: '',
                calleSecundaria3: '',
                ciudad: '',
                codPostal: '',
                colonia: '',
                id: 0,
                numExterior: '',
                numInterior: ''
            }
        }
    });

    //traemos los datos que se necesitan desde los servicios
    useEffect(() => {
        function getData() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            if(updateData) {
            fetchData(process.env.REACT_APP_ADMIN_COMPANIES + id + "/?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        setCompanyData(response);
                    }
                });
            }
        }

        getData();

    }, [id, navigate, updateData]);

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const reloadCompanyData = () => {
        setUpdateData(updateData + 1);
    }

    /*

                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link menu-admin" id="pills-creditLine-tab" data-bs-toggle="pill" data-bs-target="#pills-creditLine" type="button" role="tab" aria-controls="pills-creditLine" aria-selected="false">Línea de crédito</button>
                                                </li>

                                        <div className="tab-pane fade" id="pills-creditLine" role="tabpanel" aria-labelledby="pills-creditLine-tab">
                                            <CreditLine id={companyData.perfilId} loadData={companyData} reload={reloadCompanyData} />
                                        </div>
    */

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-5 containerTitleAdmin">
                                            <p className='tilteAdmin'>Actualizar Empresas</p>
                                        </div>
                                        <div className="col-sm-7">
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active menu-admin" id="pills-company-tab" data-bs-toggle="pill" data-bs-target="#pills-company" type="button" role="tab" aria-controls="pills-company" aria-selected="true">Empresa</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link menu-admin" id="pills-represents-tab" data-bs-toggle="pill" data-bs-target="#pills-represents" type="button" role="tab" aria-controls="pills-represents" aria-selected="false">Representante</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link menu-admin" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Perfil Empresa</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-admin-card">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-company" role="tabpanel" aria-labelledby="pills-company-tab">
                                            <Company id={id} loadData={companyData} reload={reloadCompanyData} />
                                        </div>
                                        <div className="tab-pane fade" id="pills-represents" role="tabpanel" aria-labelledby="pills-represents-tab">
                                            <Representative id={companyData.repLegalId} loadData={companyData} reload={reloadCompanyData} tipo={'Empresa'} />
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <ProfileCompany id={companyData.perfilId} loadData={companyData} reload={reloadCompanyData} tipo={'Empresa'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UpdateCompany

import React, { useState } from 'react';
import './Footer.css';
import cnbv from '../../assets/img/Logo_de_la_CNBV.png';
import buro from '../../assets/img/logoburo.png';
import condusef from '../../assets/img/logocondusef.png';
import aviso from '../../doc/AvisodePrivacidad.pdf';



function FooterOut() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowPdf = () => {
        const pdfWindow = window.open();
        pdfWindow.location.href = aviso;
    };

    const showCnbv = () => {
        window.open('https://www.gob.mx/cnbv', '_blank');
    };

    const showBuroCredito = () => {
        window.open('https://www.buro.gob.mx/', '_blank');
    };

    const showCondusef = () => {
        window.open('https://www.gob.mx/condusef', '_blank');
    };
    return (
        <div className='bgFooter'>
            <div className='row'>
                <div className='col-sm-3'>
                    <p className='text-footer2'> © 2024 Derechos reservados </p>
                </div>
                <div className='col-sm-6'>
                    <div className='bgIconFooter'>
                        <a href="#" onClick={showCnbv}>
                            <img src={cnbv} alt="" className='footerLogo' />
                        </a>
                        <a href='#' onClick={showCondusef} className='spaceLogos'>
                            <img src={condusef} alt="" className='footerLogo' />
                        </a>
                        <a href='#' onClick={showBuroCredito}>
                            <img src={buro} alt="" className='footerLogo' />
                        </a>
                    </div>
                </div>
                <div className='col-sm-3'>
                    <a className='sin' href='#' onClick={handleShowPdf}>
                        <p className='text-aviso2'>Aviso de Privacidad</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FooterOut
import React, { useState } from 'react'
import '../../Headers/Header.css'
import { useNavigate } from 'react-router-dom'
import Encrypter from '../../../services/Encrypter';
import { postData } from '../../../services/Request';

function MenuIn() {
    const userData = JSON.parse(localStorage.getItem('userData'));

    const userInitials = userData.userName? userData.userName.split(" ").map((n) => n[0]).join("") : "Usuario";

    const navigate = useNavigate();

    const handleLogout = () => {
        if (userData && userData.token) {
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            postData(process.env.REACT_APP_AUTH_SIGNOUT + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    // if (response) {
                    //     console.log("ResponseSO: " + response);
                    // } else if (error) {
                    //     console.error('Error de logout:', error);
                    // }
                    localStorage.removeItem('userData');
                    navigate('/');
                });
        } else {
            localStorage.removeItem('userData');
            navigate('/');
        }
    };

    return (
        <div className='containerNav'>

            <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav navbar-nav-scroll">
                    {((userData) && ((userData.roleId === 10) || (userData.roleId === 11))) &&
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Administración
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/dashboard">Dashboard</a></li>
                                <li><a className="dropdown-item" href="/conciliacionOperaciones">Conciliación</a></li>
                                <li><a className="dropdown-item" href="/traspasoTarjeta">Recarga tarjetas (otros)</a></li>
                            </ul>
                        </li>
                    }
                    {((userData) && (userData.roleId !== 100)) &&
                        <li className="nav-item">
                            <a className="nav-link" href="/accounts">Mis Cuentas</a>
                        </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link" href="/MyCards">Mis Tarjetas</a>
                    </li>
                    {((userData) && (userData.roleId !== 100)) &&
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Traspasos y Transferencias
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/taspasosEntreCuentasPropias">Traspasos entre cuentas propias</a></li>
                                <li><a className="dropdown-item" href="/traspasosAterceros">Transferencias a terceros</a></li>
                                <li><a className="dropdown-item" href="/spcardtransfers">Recarga de Tarjeta SitesPay</a></li>
                                <li><a className="dropdown-item" href="/transferenciasMultiples">Transferencias multiples (archivos)</a></li>
                            </ul>
                        </li>
                    }
                </ul>
            </div>
            <div className="position-absolute bottom-0 end-0 menuP">
                <button className="dropdown-toggle btn-profil" type="button" role="button" data-bs-toggle="dropdown" aria-expanded="false">{userInitials}</button>
                <ul className="dropdown-menu menuPerfil">
                    <li><a className="dropdown-item" href="/activacionInicial">Activar nueva tarjeta</a></li>
                    <li><a className="dropdown-item" href="/miPerfil">Mi perfil</a></li>
                    <li><a className="dropdown-item" href="#" onClick={handleLogout}>Cerrar sesión</a></li>
                </ul>
            </div>
        </div>
    )
}

export default MenuIn
export const equal = (value, value2) => {
    if (value === value2) {
        return "Iguales";
    }
    return null;
};

export const email = (value, errorMessage) => {
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
        return errorMessage
    }
    return null;
}

export const integer = (value) => {
    if (!/^[0-9]+$/.test(value)) {
        return "No es entero";
    }
    return null;
}

export const numeric = (value, errorMessage) => {
    if (isNaN(value)) {
        return errorMessage;
    }
    return null;
};

export const money = (value, errorMessage) => {
    if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
        return errorMessage
    }
    return null;
}

export const floatPoint = (value, errorMessage) => {
    if (!/^[0-9]*\.?([0-9]{1,2})?$/.test(value)) {
        return errorMessage;
    }
    return null;
}

export const maxNumber = (value, maxNumber, errorMessage) => {
    if (value > maxNumber) {
        return errorMessage;
    }
    return null;
}

export const maxLength = (value, maxLength, errorMessage) => {
    if (value === undefined || value === null || String(value).length > maxLength) {
        return errorMessage;
    }
    return null;
};

export const length = (value, minLength, maxLength, errorMessage) => {
    if (value === undefined || value === null || String(value).length < minLength || String(value).length > maxLength) {
        return errorMessage;
    }
    return null;
}

export const lengthTrim = (value, minLength, maxLength, errorMessage) => {
    if (value === undefined || value === null || String(value).length < minLength || String(value).length > maxLength || String(value).trim() === '') {
        return errorMessage;
    }
    return null;
}

export const sanitizeText = (dirtyText) => {
    return dirtyText.replace(/[^a-zA-Z0-9 ]/g, '');
}

export const sanitizeTextNoSpace = (dirtyText) => {
    return dirtyText.replace(/[^a-zA-Z0-9]/g, '');
}

export const sanitizeTextLettersOnly = (dirtyText) => {
    return dirtyText.replace(/[^a-zA-Z ]/g, '');
}

// export const isValidDate = (s) => {
//     var separators = ['\\.', '\\-', '\\/'];
//     var bits = s.split(new RegExp(separators.join('|'), 'g'));
//     var d = new Date(bits[2], bits[1] - 1, bits[0]);
//     return d.getFullYear() === parseInt(bits[2]) && d.getMonth() + 1 === parseInt(bits[1]);
// }

export const isValidDate = (s) => {
    var separators = ['\\.', '\\-', '\\/'];
    var bits = s.split(new RegExp(separators.join('|'), 'g'));
    var d = new Date(bits[0], bits[1] - 1, bits[2]);
    return d.getFullYear() === parseInt(bits[0]) && d.getMonth() + 1 === parseInt(bits[1]);
}

export const luhnCheck = (tarjeta) => {
    const cardArray = Array.from(tarjeta);

    if (!tarjeta)
        return true;
    if (isNaN(tarjeta))
        return true;
    if (tarjeta.length !== 16)
        return true;

    let modForDigit = 0;
    //let cardWithError = true;
    for (let i = 0; i < cardArray.length - 1; i++) {
        const digitIs = parseInt(cardArray[i]);

        // 1) validamos que sea numero
        if (Number.isNaN(digitIs)) {
            //cardOk = false;
            return true;
        }
        // 2) sacamos el módulo
        let mult = 1;
        if (((i + 1) % 2) === 1)
            mult = 2;
        modForDigit = modForDigit + ((digitIs * mult % 10) + (Math.trunc(digitIs * mult / 10)));
    }
    const dvIs = (10 - (modForDigit % 10)) % 10;
    const cardDV = parseInt(cardArray[15]);
    // console.log(dvIs);
    // console.log(cardDV);
    if (Number.isNaN(cardDV))
        return true;
    else
        if (dvIs !== cardDV)
            return true;

    return false;
}

function getMultiplier(num) {
    const division = (num + 1) % 3;
    if (division === 1) {
        return 3;
    }
    else {
        if (division === 2)
            return 7;
        else
            return 1;
    }
}

export function confirmDV(clabe) {

    const clabeArray = Array.from(clabe);
    let errorClabe = false;
    let modForDigit = 0;

    //ojo... recorremos 17 de las 18 posiciones de la clabe
    for (let i = 0; i < clabeArray.length - 1; i++) {
        const digitIs = parseInt(clabeArray[i]);

        // 1) validamos que sea numero
        if (Number.isNaN(digitIs)) {
            errorClabe = true;
            break;
        }

        // 2) sacamos el modulo de cada digito
        modForDigit = modForDigit + ((digitIs * getMultiplier(i)) % 10);
    }
    const dvIs = (10 - (modForDigit % 10)) % 10;
    const clabeDV = parseInt(clabeArray[17]);
    if (Number.isNaN(clabeDV))
        errorClabe = true;
    else
        if (dvIs !== clabeDV)
            errorClabe = true;

    return errorClabe;
}

export const ValidaRFC = (rfc, errorMessage) => {
    const _rfc_pattern_pm = /^[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}$/; // Persona moral
    const _rfc_pattern_pf = /^[A-ZÑ&]{4}\d{6}[A-Z0-9]{3}$/; // Persona física

    if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
        return null;
    } else {
        return errorMessage;
    }
};

// Validar CURP
export const ValidarCURP = (curp, errorMessage) => {
    const CURP_REGEX = /^[A-Z][AEIOUX][A-Z]{2}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[MH][A-Z]{2}[BCDFGHJKLMNÑPQRSTVWXYZ]{3}[A-Z\d]\d$/;
    if (curp.match(curp)) {
        return true;
    } else {
        return errorMessage;
    }
};

import React from "react";
import imgEmpresa from '../../assets/img/cuentaempresa.png';
import imgTarjeta from '../../assets/img/tarjeta.png';
import imgTerminal from '../../assets/img/terminal.png';

function ComponentProducts() {
  return (
    <div className="bgProductos">
      <div className="lineProducts">
        <h2 className="titleProducts" >Nuestros Productos</h2>
      </div>
      <div className="text-products">
        <h4 className="text-label">Nuestros productos <span className="resaltar">están diseñados</span> para que
          <span className="resaltar"> nuestros clientes puedan mejorar </span></h4>
        <h4 className="text-label"><span className="resaltar">sus necesidades financieras y crediticias </span> de manera fácil y sencilla</h4>
      </div>
      <div>
        <div className="row cardsProdc">
          <div className="col-sm-4">
            <div className="gbProductsImg">
              <img className="imgProducts" src={imgEmpresa} alt="" />
            </div>
            <div className="cardProducts1">
              <div className="cardTitle">
                <h3 className="productsTitle">Cuenta Empresarial</h3>
              </div>
              <div className="cardBody">
                <p className="textProducts">Ofrecemos una cuenta empresarial con
                  <span className="resaltar"> apertura en 3 días, banca 24/7, transferencias SPEI, y depósitos en efectivo</span>.
                  Con soporte multiempresa y multiusuario, está<span className="resaltar"> respaldada por ASP Integra </span>
                  Básica y autorizada por <span className="resaltar"> CONDUSEF y CNBV</span>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="gbProductsImg">
              <img className="imgProducts" src={imgTarjeta} alt="" />
            </div>
            <div className="cardProducts2">
              <div className="cardTitle">
                <h3 className="productsTitle">Tarjeta de Crédito Garantizada </h3>
              </div>
              <div className="cardBody">
                <p className="textProducts">
                  La <span className="resaltar"> Tarjeta Internacional del Gasto Empresarial </span>
                  permite gestionar los recursos del Negocio de SitesPay, respaldados por
                  <span className="resaltar"> MasterCard, aceptada globalmente </span>
                  y con funciones como <span className="resaltar"> retiros, CONTACTLESS, y compras en línea</span>.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="gbProductsImg">
              <img className="imgProducts" src={imgTerminal} alt="" />
            </div>
            <div className="cardProducts3">
              <div className="cardTitle">
                <h3 className="productsTitle">Terminal Punto de venta</h3>
              </div>
              <div className="cardBody">
                <p className="textProducts">
                  Ofrecemos tarjetas y domiciliación de cargos, con
                  <span className="resaltar"> motivo de ventas. Terminales </span>
                  adaptadas a cada necesidad, cumpliendo con
                  <span className="resaltar"> estándares EMV y depósitos rápidos</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponentProducts;

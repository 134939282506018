import React, { useState } from 'react';
import { postData } from "../../services/Request";
import Encrypter from "../../services/Encrypter";
import { useNavigate } from "react-router-dom";
import Message from "../../components/Message/Message";
import RecoverPassModal from "../Modals/RecoverPassModal";



function ComponentLogin() {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [otpRequired, setOtpRequired] = useState(0);
    const [otpToken, setOtpToken] = useState("");
    const [alert, setAlert] = useState({ tipo: "", msn: "", show: false });
    const navigate = useNavigate();

    const handleCancel = () => {
        setOtpToken('');
        setOtpRequired(0);
        setAlert({ tipo: "", msn: "", show: false });
    }

    const handleLogin = () => {
        setAlert({ tipo: "", msn: "", show: false });
        const data = { username, cryptedPwd: password, otpToken: otpToken };
        const dataEnc = Encrypter.aesEncrypt(
            process.env.REACT_APP_API_SECRET,
            JSON.stringify(data)
        );
        const headers = {
            headers: {
                authorization: "APIAuth " + process.env.REACT_APP_API_KEY + ":",
            },
        };

        postData(
            process.env.REACT_APP_AUTH_SIGNIN,
            { data: dataEnc },
            headers
        ).then(({ response, error }) => {
            if (response) {
                const uData = JSON.parse(response);

                //console.log(uData,  uData.token);
                if( !uData.token ||  uData.token === 'TokenError' ) {
                    // error
                    setAlert( { tipo: "danger", msn: "Hubo un error en su verificación. Por favor, inténtalo de nuevo.", show: true } );
                    return;
                }
                if( uData.token === 'TokenRequiredSent' ) {
                    // user requires OTP to login
                    setOtpRequired(1);
                    return;
                }
                // si ninguna de las anteriores.. hay token y entonces sesion activa
            
                localStorage.setItem("userData", response);
                if (uData.roleId === 100) {
                    navigate("/MyCards");
                } else {
                    navigate("/Accounts");
                }
            } else if (error) {
                if (error.response.status === 400) {
                    setAlert({
                        tipo: "danger",
                        msn: "Credenciales incorrectas. Por favor, inténtalo de nuevo.",
                        show: true,
                    });
                } else if (error.response.status === 500) {
                    setAlert({ tipo: "danger", msn: error.message, show: true });
                } else {
                    console.log("E login: " + error);
                    setAlert({
                        tipo: "danger",
                        msn: "Ooops algo nos salió mal!",
                        show: true,
                    });
                }
            }
        });
    };

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='bgContactLogin'>
            <div className='bgPhone'>
                <div className='bgPhoneLine' >
                    <div id="login" className='row rowHome'>
                        <div className='col-sm-5'>
                            <div className='containerText'>
                                <h1 className='titelLogin2'>SOLUCIONES</h1>
                                <h2 className='titelLogin2'> FINANCIERAS</h2>
                                <label className='textLogin3'>SEGURAS Y ACCESIBLES</label>
                                <div>
                                    <label className='textLogin4'>Gestiona tus pagos y cobros con Sitespay</label>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-5'>
                            <div className='containerFormLogin'>
                                <div className='titleLogin'>
                                    {
                                    (otpRequired===0) &&
                                    <h3 className='textLoginTitle'>Iniciar Sesion </h3>
                                    }
                                    {
                                    (otpRequired===1) &&
                                    <h3 className='textLoginTitle'>Ingrese su token </h3>
                                    }
                                </div>
                                <div>
                                    <Message alert={alert} closeAlert={closeAlert} />
                                </div>
                                {
                                    (otpRequired===0) &&
                                    <form>
                                        <div className='bg-inputLogin'>
                                            <input className="form-control-login2" type="email" placeholder="Email" onChange={(e) => setUserName(e.target.value)} />
                                        </div>
                                        <div className='bg-inputLogin'>
                                            <input className="form-control-login2" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className='bgBtnLogin'>
                                            <button className="btn btnLogin2" type="button" onClick={handleLogin}>Iniciar sesión</button>
                                        </div>
                                    </form>
                                }
                                {
                                    (otpRequired===1) &&
                                    <form>
                                        <div className='bg-inputLogin'>
                                            <input className="form-control-login2" type="text" placeholder="Ingrese su token" value={otpToken} onChange={(e) => setOtpToken(e.target.value)} />
                                        </div>
                                        <div className='bgBtnLogin'>
                                            <button className="btn btnLogin2" type="button" onClick={handleLogin}>Continuar</button>
                                        </div>
                                        <div className='bgBtnLogin'>
                                            <button className="btn btnLogin2" type="button" onClick={handleCancel}>Regresar</button>
                                        </div>
                                    </form>
                                }

                                <div className='containerTxtForget'>
                                    <p className="txtForget2" onClick={handleShow}>¿Olvidaste tu contraseña?</p>
                                </div>
                                <RecoverPassModal show={show} handleClose={handleClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComponentLogin
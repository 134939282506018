import React from 'react';

import edificio from '../../assets/img/office-build.png';
import grafica from '../../assets/img/grafica.png';
import iconLogo from '../../assets/img/ISOTIPO2.png';

import check from '../../assets/img/iconotarjetahabiente.png';
import star from '../../assets/img/iconosestrellas.png';

function ComponentDiscover() {
    return (
        <div className="bgSolutions">
            <div className="lineDiscover">
                <h2 className="titleDescovery">Descubre</h2>
                <h3 className='subTitleSolutions'>nuestras soluciones</h3>
                <h3 className='subTitleSolutions'>financieras seguras </h3>
                <h4 className='subTitleSolutions2'>y accesibles hoy mismo.</h4>
            </div>
            <div className='bgText'>
                <p className='txtSolutions'>
                    En Sitepay, facilitamos la
                    <span className='resaltar'> gestión financiera para empresas y personas</span>,
                    ofreciendo soluciones <span className='resaltar'> efectivas y accesibles </span>
                    que mejoran la <span className='resaltar'> calidad de vida </span> de nuestros clientes
                    de manera responsable.
                </p>
            </div>
            <button type="button" className="btn btn-light2">Más Información</button>
            <div className='colDescubre'>
                <div className='row'>
                    <div className='col-sm-2'>
                        <div className='col-icon-des'>
                            <img className='iconCheck' src={check} alt='' />
                            <h1 className='titleIconDes'>1,000+</h1>
                            <p className='texIconDesc'>Tarjetahabientes</p>
                        </div>
                    </div>
                    <div className='col-sm-10'>
                        <div className='col-icon-des'>
                            <img className='iconStar' src={star} alt='' />
                            <h1 className='titleIconDes'>500</h1>
                            <p className='texIconDesc'>Clientes satisfechos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img className='imgEdificio' src={edificio} alt='' />
                <img className='imgGrafica' src={grafica} alt='' />
                <img className='iconLogo2' src={iconLogo} alt='' />
            </div>
        </div>
    )
}

export default ComponentDiscover
import React, { useState, useEffect } from "react";

import "../../Headers/Header.css";
import ln from '../../../assets/img/Linkedin2.png';
import x from '../../../assets/img/X.png';

function MenuOut() {

  const openTwitter = () => {
    window.open('https://x.com/sitespaymx', '_blank', 'noopener,noreferrer');
  };

  const openLinkedIn = () => {
    window.open(' https://www.linkedin.com/company/sitespay/', '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <div className="navbarScrollOut">
        <div className="collapse navbar-collapse" id="navbarScrollOut">
          <nav className="nav-out">
            <ul className="navbar-nav navbar-nav-out">
              <li className="nav-item2" >
                <a className="nav-link2" href="#login">
                  Inicio
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" href="#productos">
                  Productos
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" href="#soluciones">
                  Soluciones
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" href="#descubre">
                  Descubre
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" href="#contacto">
                  Contacto
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" onClick={openLinkedIn}>
                  <img className="iconLogin" src={ln} alt="" />
                </a>
              </li>
              <li className="nav-item2" >
                <a className="nav-link2" onClick={openTwitter}>
                  <img className="iconLogin" src={x} alt="" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default MenuOut;
